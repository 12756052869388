import Card from '@shoreag/base/Card';
import Dropdown from '@shoreag/base/Dropdown';
import Input from '@shoreag/base/Input';
import PropTypes from 'prop-types';
import React, { useContext } from 'react';
import ReactGA from 'react-ga';
import Spinner from '@shoreag/base/Spinner';
import get from 'lodash/get';
import { AuthContext } from '@shoreag/auth';
import { FORM_ERROR } from 'final-form';
import { Field, Form as FinalForm } from 'react-final-form';
import { composeValidations, email, required } from '@shoreag/validations';
import { useMutation, useQuery, useApolloClient } from 'react-apollo';
import ToggleSwitch from '@shoreag/base/ToggleSwitch';
import FormError from '../FormError';
import MultiSelectDropdown from '../MultiSelectDropdown';
import NotFoundPage from '../../routes/default/404';
import Route from '../Route';
import SubmitButton from '../SubmitButton';
import allPartnerDropdownQuery from '../../graphql/queries/all-partner-dropdown.gql';
import allUserRoleDropdownQuery from '../../graphql/queries/all-user-role-dropdown.gql';
import createUserMutation from '../../graphql/mutations/create-user.gql';
import updateUserMutation from '../../graphql/mutations/update-user.gql';
import userQuery from '../../graphql/queries/user.gql';

const UserCreatePage = ({ navigate, userId }) => {
  const { user: authUser } = useContext(AuthContext);
  if (!authUser.isAdmin) return <NotFoundPage />;

  const client = useApolloClient();
  const [createUser] = useMutation(createUserMutation);
  const [updateUser] = useMutation(updateUserMutation);

  const { data: userData, loading } = useQuery(userQuery, {
    skip: !userId,
    variables: { id: userId },
  });

  const user = get(userData, 'user', {});
  if (loading) return <Spinner />;
  const tradingPartners = get(user, 'tradingPartners', []).map(({ id }) => id);
  const initialValues = {
    ...user,
    activeRole: get(user, 'activeRole.id'),
    tradingPartners,
    tradingPartnersAssociate: tradingPartners.length > 0,
  };
  return (
    <Route
      header={{
        icon: 'users',
        title: `${userId ? 'Update' : 'Create'} User`,
        type: 'User',
      }}
      isPrivate
    >
      <FinalForm
        initialValues={initialValues}
        keepDirtyOnReinitialize
        onSubmit={async (values) => {
          const updatedValues = {};
          Object.keys(values).forEach((key) => {
            if (key !== 'tradingPartnersAssociate') {
              updatedValues[key] = values[key];
            }
          });
          updatedValues.tradingPartners = values.tradingPartnersAssociate
            ? values.tradingPartners
            : [];
          try {
            const payload = { variables: { ...updatedValues } };

            if (values.id) {
              const {
                data: {
                  updateUser: { id },
                },
              } = await updateUser(payload);

              ReactGA.event({
                action: 'User Edited',
                category: 'Users',
                label: id,
              });
            } else {
              const {
                data: {
                  createUser: { id },
                },
              } = await createUser(payload);

              ReactGA.event({
                action: 'User Created',
                category: 'Users',
                label: id,
              });
            }

            navigate(`/users/`);
          } catch (e) {
            return {
              [FORM_ERROR]: get(e, 'graphQLErrors[0].message', e.message),
            };
          }
        }}
        render={(formContext) => {
          const isPartnerAssociated =
            formContext?.values?.tradingPartnersAssociate;
          return (
            <form onSubmit={formContext.handleSubmit}>
              <Card maxWidth="maxWidths.form" mx="auto">
                <Field
                  component={Input}
                  disabled={user.id}
                  label="Email"
                  name="email"
                  validate={composeValidations(required, email)}
                />
                <Field
                  component={Dropdown}
                  label="Role"
                  loadOptions={async () => {
                    const {
                      data: { allUserRole },
                    } = await client.query({
                      query: allUserRoleDropdownQuery,
                    });

                    return [
                      {
                        label: 'Administrator',
                        value:
                          'spr:bz:user-role::00000000-0000-0000-0000-000000000000',
                      },
                      ...allUserRole
                        .filter((role) => !role.disabled)
                        .map(({ id, name }) => ({
                          label: name,
                          value: id,
                        })),
                    ];
                  }}
                  name="activeRole"
                  validate={required}
                />
                <Field
                  component={ToggleSwitch}
                  errorSx={{
                    ml: 4,
                    mt: 0,
                  }}
                  label="Associate Trading Partners"
                  labelSx={{
                    ml: 0,
                    mr: 6,
                    order: 'unset',
                  }}
                  name="tradingPartnersAssociate"
                  type="checkbox"
                  wrapperSx={{
                    alignItems: 'center',
                    display: 'flex',
                  }}
                />
                {isPartnerAssociated && (
                  <Field
                    component={MultiSelectDropdown}
                    // label="Trading Partners"
                    loadOptions={async () => {
                      const {
                        data: { allPartner },
                      } = await client.query({
                        query: allPartnerDropdownQuery,
                      });

                      return allPartner.map(({ id, partnerName }) => ({
                        label: partnerName,
                        value: id,
                      }));
                    }}
                    name="tradingPartners"
                    validate={required}
                    wrapperSx={{
                      mt: 3,
                    }}
                  />
                )}
              </Card>
              <FormError>{formContext.submitError}</FormError>
              <SubmitButton mt="lg" submitting={formContext.submitting}>
                {userId ? 'Update' : 'Create'} User
              </SubmitButton>
            </form>
          );
        }}
      />
    </Route>
  );
};

UserCreatePage.propTypes = {
  navigate: PropTypes.func.isRequired,
  userId: PropTypes.string,
};

UserCreatePage.defaultProps = {
  userId: null,
};

export default UserCreatePage;
