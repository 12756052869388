import Box from '@shoreag/base/Box';
import Card from '@shoreag/base/Card';
import Dropdown from '@shoreag/base/Dropdown';
import DropdownMenu from '@shoreag/base/DropdownMenu';
import React, { useContext, useState } from 'react';
import Table from '@shoreag/base/Table';
import noop from 'lodash/noop';
import { AuthContext } from '@shoreag/auth';
import { Field, Form } from 'react-final-form';
import { capitalCase } from 'change-case';
import { useMutation, useQuery } from 'react-apollo';
import DotsIcon from '../DotsIcon';
import NotFoundPage from '../../routes/default/404';
import Route from '../Route';
import ToolbarButton from '../ToolbarButton';
import allUserQuery from '../../graphql/queries/all-user.gql';
import createUserMutation from '../../graphql/mutations/create-user.gql';
import deleteUserMutation from '../../graphql/mutations/delete-user.gql';
import disableUserMutation from '../../graphql/mutations/disable-user.gql';
import enableUserMutation from '../../graphql/mutations/enable-user.gql';
import setActionDropdown from '../../utilities/set-action-dropdown';
import getFullName from '../../utilities/get-full-name';
import useSnackbar from '../../utilities/use-snackbar';
import { PERMISSION_ACTIONS_MAP } from '../../utilities/constants';

const UserDashboardPage = () => {
  const { isAuthenticated, user } = useContext(AuthContext);
  if (isAuthenticated && !user.isAdmin) return <NotFoundPage />;

  const { data, loading } = useQuery(allUserQuery, {
    fetchPolicy: 'network-only',
  });

  const [createUser] = useMutation(createUserMutation);
  const [deleteUser] = useMutation(deleteUserMutation);
  const [disableUser] = useMutation(disableUserMutation);
  const [enableUser] = useMutation(enableUserMutation);
  const [setSnack] = useSnackbar();
  const [setErrorSnack] = useSnackbar({ error: true });
  const [userStatusFilter, setUserStatusFilter] = useState('ALL');

  const users = data?.allUser || [];

  return (
    <Route
      header={{
        icon: 'users',
        rightContainer: (
          <ToolbarButton icon="plus" label="Create User" link="/users/create" />
        ),
        title: 'Dashboard',
        type: 'User',
      }}
      isPrivate
    >
      <Form initialValues={{ status: userStatusFilter }} onSubmit={noop}>
        {() => (
          <Box
            sx={{
              bg: 'grays.2',
              borderRadius: 2,
              flexWrap: 'wrap',
              fontSize: 2,
              mb: 5,
              ml: 'auto',
              p: 5,
              width: 'calc(100% / 3)',
            }}
          >
            <Box sx={{ p: 4 }}>
              <Field
                component={Dropdown}
                label="User Status"
                name="status"
                onChange={setUserStatusFilter}
                options={[
                  { label: 'All', value: 'ALL' },
                  { label: 'Active', value: 'CONFIRMED' },
                  { label: 'Disabled', value: 'DISABLED' },
                  { label: 'Invited', value: 'FORCE_CHANGE_PASSWORD' },
                ]}
              />
            </Box>
          </Box>
        )}
      </Form>
      <Card p={0}>
        <Table
          header={[
            'Name',
            'Email',
            'Trading Partners',
            'Active Role',
            'Permissions',
            'Status',
            '',
          ]}
          isLoading={loading}
          renderEmpty="No users"
          rows={users
            .filter(
              (u) => userStatusFilter === 'ALL' || u.status === userStatusFilter
            )
            .map((user) => {
              const {
                id,
                status,
                activeRole,
                tradingPartners,
                givenName,
                familyName,
                email,
              } = user;

              const isForceChangedPassword = status === 'FORCE_CHANGE_PASSWORD';
              const isDisabled = status === 'DISABLED';

              const { dropdownItems, dropdownActions } = setActionDropdown([
                {
                  action: `/users/${id}/edit`,
                  display: true,
                  label: ['edit', 'Edit'],
                },
                {
                  action: async () => {
                    try {
                      await createUser({
                        variables: {
                          ...user,
                          activeRole: activeRole.id,
                          resendInvite: true,
                          tradingPartners: tradingPartners.map((p) => p.id),
                        },
                      });

                      setSnack('Invite resent');
                    } catch (e) {
                      setSnack('Resend failed, try again later');
                    }
                  },
                  display: isForceChangedPassword,
                  label: ['repeat', 'Resend Invite'],
                },
                {
                  action: async () => {
                    try {
                      await deleteUser({
                        update: (client) => {
                          client.writeQuery({
                            data: {
                              allUser: data.allUser.filter((u) => u.id !== id),
                            },
                            query: allUserQuery,
                          });
                        },
                        variables: user,
                      });

                      setSnack('User deleted');
                    } catch (e) {
                      setErrorSnack('Deletion failed, try again later');
                    }
                  },
                  display: isForceChangedPassword,
                  label: ['close', 'Delete User'],
                },
                {
                  action: async () => {
                    try {
                      await enableUser({
                        update: (client) => {
                          client.writeQuery({
                            data: {
                              allUser: users.map((u) => ({
                                ...u,
                                status: u.id === id ? 'CONFIRMED' : u.status,
                              })),
                            },
                            query: allUserQuery,
                          });
                        },
                        variables: user,
                      });

                      setSnack('User enabled');
                    } catch (e) {
                      setErrorSnack('Enable failed, try again later');
                    }
                  },
                  display: isDisabled,
                  label: ['check', 'Enable User'],
                },
                {
                  action: async () => {
                    try {
                      await disableUser({
                        update: (client) => {
                          client.writeQuery({
                            data: {
                              allUser: users.map((u) => ({
                                ...u,
                                status: u.id === id ? 'DISABLED' : u.status,
                              })),
                            },
                            query: allUserQuery,
                          });
                        },
                        variables: user,
                      });
                      setSnack('User disabled');
                    } catch (e) {
                      setErrorSnack('Disable failed, try again later');
                    }
                  },
                  display: true,
                  label: ['close', 'Disable User'],
                },
              ]);

              return [
                getFullName([givenName || '', familyName || '']),
                email || '',
                <>
                  {(tradingPartners || []).map((p, i) => (
                    <Box key={i} sx={{ whiteSpace: 'nowrap' }}>
                      {p.partnerName}
                    </Box>
                  ))}
                </>,
                activeRole?.name || 'Administrator',
                <>
                  {!activeRole || activeRole.name === 'Administrator'
                    ? 'All'
                    : (activeRole?.permissions || []).map((p, i) => (
                        <Box key={i} sx={{ whiteSpace: 'nowrap' }}>
                          {PERMISSION_ACTIONS_MAP[p.actions]}{' '}
                          {capitalCase(p.resource)}
                        </Box>
                      ))}
                </>,
                (() => {
                  switch (status) {
                    case 'FORCE_CHANGE_PASSWORD': {
                      return 'Invited';
                    }

                    case 'DISABLED': {
                      return 'Disabled';
                    }

                    default: {
                      return 'Active';
                    }
                  }
                })(),
                <Box sx={{ float: 'right' }}>
                  <DropdownMenu
                    button={<DotsIcon />}
                    itemActions={dropdownActions}
                    items={dropdownItems}
                  />
                </Box>,
              ];
            })}
        />
      </Card>
    </Route>
  );
};

export default UserDashboardPage;
