import React from 'react';
import { Router } from '@reach/router';
import UserCreatePage from '../../components/UserCreatePage';
import UserDashboardPage from '../../components/UserDashboardPage';
import NotFoundPage from './404';

const UsersRouter = (props) => (
  <Router>
    <UserCreatePage {...props} path="/users/:userId/edit" />
    <UserCreatePage {...props} path="/users/create" />
    <UserDashboardPage {...props} path="/users/" />
    <NotFoundPage default />
  </Router>
);

export default UsersRouter;
